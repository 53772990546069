import React from "react"
import styled from "styled-components"
import TextBlock from "./textBlock"
import HeroBlock from "./heroBlock"
import TabBlock from "./tabBlock"
import TableBlock from "./tableBlock"
import GalleryBlock from "./galleryBlock"
import BenefitsBlock from "./benefitsBlock"
import DocumentsBlock from "./documentsBlock"
import ShowcaseBlock from "./showcaseBlock"
import ImageBlockLegacy from "./imageBlockLegacy"
import ItemsBlock from "./itemsBlock"
import { Box, Container } from "@theme-ui/components"

const CategoryPageContent = ({ page, fullwidth }) => (
  <Content>
    {page.content.map((section, index) => (
      <section key={section.id}>
        {section.model.apiKey === "gallery_block" && (
          <GalleryBlock text={section.text} gallery={section.gallery} />
        )}
        {section.model.apiKey === "hero_block" && (
          <HeroBlock
            title={section.title}
            text={section.text}
            image={section.image}
            gallery={section.gallery}
            documents={section.documents}
          />
        )}
        {section.model.apiKey === "text_block" && (
          <Container>
            <Box>
              <TextBlock
                title={section.title}
                text={section.text}
                highlight={section.highlight}
                fullwidth
              />
            </Box>
          </Container>
        )}
        {section.model.apiKey === "image_block" && (
          <ImageBlockLegacy
            title={section.title}
            text={section.text}
            image={section.image}
            highlight={section.highlight}
            alignment={section.rightAlignment}
          />
        )}
        {section.model.apiKey === "benefits_block" && (
          <BenefitsBlock
            title={section.title}
            text={section.text}
            benefits={section.benefits}
            highDensity={section.highDensity}
            fullwidth={fullwidth}
          />
        )}
        {section.model.apiKey === "tab_block" && (
          <TabBlock tabs={section.details} />
        )}
        {section.model.apiKey === "items_block" && (
          <ItemsBlock title={section.title} items={section.items} />
        )}
        {section.model.apiKey === "table_block" && (
          <TableBlock
            title={section.title}
            table={section.table}
            fullwidth={fullwidth}
          />
        )}
        {section.model.apiKey === "documents_block" && (
          <DocumentsBlock documents={section.documents} fullwidth={fullwidth} />
        )}
        {section.model.apiKey === "showcase_block" && (
          <ShowcaseBlock
            title={section.title}
            showcase={section.showcase}
            fullwidth={fullwidth}
          />
        )}
      </section>
    ))}
  </Content>
)

const Content = styled.div``

export default CategoryPageContent

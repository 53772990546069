import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import { Col, Container, Row } from "react-grid-system"
import HeroHeader from "../components/heroHeader"
import NavCategories from "../components/navCategories"
import CallToAction from "../components/callToAction"
import CategoryPageContent from "../components/categoryPageContent"
import { getCategoryPath } from "../components/pathUtils"
import { HelmetDatoCms } from "gatsby-source-datocms"

const CategoryPage = ({ data: { page }, pageContext, location }) => {
  // console.log(page)
  const lang = pageContext.locale
  const i18nPaths = page._allSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getCategoryPath(page, locale.locale),
    }
  })

  return (
    <Layout
      lang={lang}
      title={page.title}
      location={location}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags} >
        <html lang={lang} />
      </HelmetDatoCms>
      <HeroHeader
        title={page.title}
        subtitle={page.subtitle}
        heroImage={page.highlight ? page.heroImage : page.application.heroImage}
        highlight={page.highlight}
        logo={page.logo}
      />
      {!page.highlight ? (
        <Wrapper>
          <Container>
            <Row>
              <ColNav xl={3}>
                <Link
                  to={
                    lang === "it"
                      ? pageContext.parentSlug
                      : `/${lang}/${pageContext.parentSlug}`
                  }
                >
                  {page.application.title}
                </Link>
                <NavCategories lang={lang} slug={page.application.slug} />
              </ColNav>
              <Col xl={9}>
                <CategoryPageContent page={page} />
              </Col>
            </Row>
          </Container>
        </Wrapper>
      ) : (
        <CategoryPageContent page={page} fullwidth />
      )}

      <CallToAction
        title={page.callToAction.title}
        backgroundImage={page.callToAction.backgroundImage}
        slug={page.callToAction.slug}
        name={page.callToAction.name}
      />
    </Layout>
  )
}

const Wrapper = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
`

const ColNav = styled(Col)`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default CategoryPage

export const query = graphql`
  query CategoryPageQuery($slug: String!, $locale: String!) {
    page: datoCmsCategoryPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      ...AllCategorySlugLocales
      title
      subtitle
      slug
      logo {
        url
      }
      highlight
      heroImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      application {
        title
        slug
        ...AllApplicationSlugLocales
        heroImage {
          fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      callToAction {
        title
        backgroundImage {
          fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        slug
        name
      }
      id
      locale
      content {
        ... on DatoCmsTableBlock {
          id
          title
          table
          model {
            apiKey
          }
        }
        ... on DatoCmsShowcaseBlock {
          id
          title
          showcase {
            fluid(maxWidth: 1440, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsGalleryBlock {
          text
          gallery {
            fluid(maxWidth: 720, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsBenefitsBlock {
          id
          title
          text
          highDensity
          benefits {
            id
            text
            image {
              url
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsDocumentsBlock {
          id
          documents {
            id
            title
            documents {
              originalId
              url
              title
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsItemsBlock {
          originalId
          title
          items {
            originalId
            name
            image {
              fluid(maxWidth: 720, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
            items {
              originalId
              code
              text
              image {
                fluid(maxWidth: 720, imgixParams: { fm: "jpg" }) {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTabBlock {
          id
          details {
            title
            content {
              ... on DatoCmsTableBlock {
                id
                title
                table
                model {
                  apiKey
                }
              }
              ... on DatoCmsTextBlock {
                title
                text
                highlight
                id
                model {
                  apiKey
                }
              }
              ... on DatoCmsHeroBlock {
                ...HeroBlockDetails
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsHeroBlock {
          ...HeroBlockDetails
        }
        ... on DatoCmsTextBlock {
          title
          text
          highlight
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsImageBlock {
          title
          text
          image {
            fluid(maxWidth: 520, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          highlight
          rightAlignment
          id
          model {
            apiKey
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }

  fragment AllCategorySlugLocales on DatoCmsCategoryPage {
    _allSlugLocales {
      locale
      value
    }
  }
`

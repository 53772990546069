import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import pdfIcon from "../images/pdf-icon.svg"
import { Container, Box, Grid, Image, Link } from "@theme-ui/components"

const DocumentsBlock = ({ documents, fullwidth }) => {
  // console.log(documents)
  return (
    <>
      {fullwidth ? (
        <Wrapper>
          <Container sx={{ maxWidth: 1024 }}>
            <Documents documents={documents} />
          </Container>
        </Wrapper>
      ) : (
        <Documents documents={documents} />
      )}
    </>
  )
}

const Documents = ({ documents }) => (
  <StyledBox>
    <Grid columns={[1, 1, 2]} gap={32}>
      {documents.map(document => (
        <Box key={document.originalId}>
          <Card>
            <Details>
              <div>
                <Title>{document.title}</Title>
              </div>
              {document.documents.map(link => (
                <Link
                  sx={{ color: "light" }}
                  href={link.url}
                  target="_blank"
                  rel="nofollow noopener"
                >
                  {link.title || "Download"}
                </Link>
              ))}
            </Details>
            <Box sx={{ position: "absolute", bottom: 3, right: 3 }}>
              <Image sx={{ height: "40px" }} src={pdfIcon} />
            </Box>
          </Card>
        </Box>
      ))}
    </Grid>
  </StyledBox>
)

const Wrapper = styled.div`
  background: #f3f6f8;
`
const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Title = styled.p`
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
  display: block;
`
const Icon = styled.div`
  display: flex;
  align-items: flex-end;
`

const StyledBox = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
`

const Card = styled.div`
  position: relative;
  min-height: 14rem;
  padding: 2rem;
  color: white;
  background: ${DesignTokens.colors.primary[500]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default DocumentsBlock

import { Box } from "@theme-ui/components"
import React from "react"
import styled from "styled-components"
import RichContentTextBlock from "./richContentTextBlock"

const Benefit = ({ text, image }) => {
  return (
    <Wrapper>
      {image && (
        <Box sx={{mr:4}}>
          <img src={image.url} />
        </Box>
      )}
      <RichContentTextBlock html={text} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
  /* & > div {
    margin-left: 2rem;
  } */
  @media screen and (max-width: 768px) {
    flex-direction: column;
    & > div {
      margin-left: 0;
    }
  }
`

export default Benefit

import React from "react"
import { Container, Grid, Heading } from "@theme-ui/components"
import ItemModalBlock from "./itemModalBlock"

const ItemsBlock = ({ title, items }) => {
  return (
    <Container>
      <Heading sx={{ color: "primary", fontSize: 5, mb: 4 }}>{title}</Heading>
      <Grid columns={[1, 2, 3, 4]}>
        {items.map(item => (
          <ItemModalBlock item={item} />
        ))}
      </Grid>
    </Container>
  )
}

export default ItemsBlock

import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Container, Grid, Flex } from "@theme-ui/components"
import RichContentTextBlock from "./richContentTextBlock"
import background from "../images/green-gallery-background.svg"

const GalleryBlock = ({ text, gallery, page }) => (
  <Grid columns={[1, 1, 2, 2]}>
    <Container>
      <Flex sx={{ alignItems: "center", height: "100%" }}>
        <RichContentTextBlock html={text} page={page} />
      </Flex>
    </Container>
    <Flex
      sx={{
        backgroundImage:
          "linear-gradient(90deg, rgb(0 121 61), rgb(0 121 61)), linear-gradient(90deg, rgb(0 121 61), rgb(0 121 61)), linear-gradient(90deg, rgb(0 121 61), rgb(0 121 61)), linear-gradient(90deg, rgb(0 121 61), rgb(0 121 61))",
        backgroundSize: "50px 0%, 90% 50px, 50px 100%, 70% 60px",
        backgroundPosition: "0px 0px, 100% 0px, 100% 0px, 100% 100%",
        backgroundRepeat: "no-repeat",
        paddingTop: "5%",
        paddingBottom: "5%",
        "& > *": {
          flexGrow: 1,
        },
      }}
    >
      <Img fluid={gallery[0].fluid} />
    </Flex>
  </Grid>
)

export default GalleryBlock

import React, { useState } from "react"
import RichContentTextBlock from "./richContentTextBlock"
import { Box, Grid, Text } from "@theme-ui/components"
import Img from "gatsby-image"
import ReactModal from "react-modal"
import { useBreakpointIndex } from "@theme-ui/match-media"

const ItemModalBlock = ({ item }) => {
  const index = useBreakpointIndex()
  ReactModal.defaultStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      width: index === 0 ? "90vw" : "70vw",
      padding: "0 !important",
      background: "#000",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      maxHeight: "80vh",
    },
  }

  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(item.items[0])
  return (
    <Box sx={{ img: { display: "block", margin: 0 } }}>
      <ReactModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        ariaHideApp={false}
        closeTimeoutMS={500}
      >
        <Box sx={{ backgroundColor: "primary" }}>
          <Grid columns={[1, 1, 1, 2]} gap={0}>
            <Box
              sx={{
                maxHeight: ["40vh", "40vh", "40vh", "100%"],
                ".gatsby-image-wrapper": { height: "100%" },
              }}
            >
              <Img fluid={selectedItem.image.fluid} alt="" />
            </Box>
            <Box p={[3, 4]}>
              <Box sx={{ mb: 3 }}>
                {item.items.map(item => (
                  <Text
                    as="div"
                    sx={{
                      mr: 3,
                      mb: 0,
                      color: "primary",
                      fontSize: 1,
                      fontWeight: "normal",
                      cursor: "pointer",
                      paddingX: 3,
                      paddingY: 2,
                      backgroundColor: "white",
                      borderRadius: "full",
                      display: "inline-block",
                      opacity:
                        item.originalId === selectedItem.originalId ? 1 : 0.5,
                    }}
                    onClick={() => {
                      setSelectedItem(item)
                    }}
                  >
                    {item.code}
                  </Text>
                ))}
              </Box>
              <RichContentTextBlock html={selectedItem.text} color="white" />
            </Box>
          </Grid>
        </Box>
      </ReactModal>
      <Img fluid={item.image.fluid} alt="" />
      <Box
        sx={{
          backgroundColor: "primary",
          position: "relative",
          px: 3,
          py: 3,
        }}
      >
        {item.items.map(item => (
          <Text
            sx={{
              mr: 3,
              color: "light",
              fontSize: 1,
              fontWeight: "normal",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(true)
              setSelectedItem(item)
            }}
          >
            {item.code}
          </Text>
        ))}
      </Box>
    </Box>
  )
}

export default ItemModalBlock

import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import { Lead } from "./typography"
import Img from "gatsby-image"

const ShowcaseBlock = ({ title, showcase, fullwidth }) => (
  <>
    {fullwidth ? (
      <Wrapper>
        <Container>
          <Showcase title={title} showcase={showcase} />
        </Container>
      </Wrapper>
    ) : (
      <Showcase title={title} showcase={showcase} />
    )}
  </>
)

const Showcase = ({ title, showcase }) => (
  <Box>
    <Row justify="center">
      <Col xl={10}>
        <Details>
          <Lead>{title}</Lead>
          <Img fluid={showcase.fluid} />
        </Details>
      </Col>
    </Row>
  </Box>
)

const Wrapper = styled.div`
  background: #f3f6f8;
`
const Details = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Box = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
`

export default ShowcaseBlock

import React from "react"
import styled from "styled-components"
import { Container } from "react-grid-system"
import { Heading } from "./typography"
import customBorder from "../images/custom-border.svg"
import RichContentTextBlock from "./richContentTextBlock"
import Benefit from "./benefit"
import { Grid, Box } from "@theme-ui/components"

const BenefitsBlock = ({ title, text, benefits, fullwidth, highDensity }) => (
  <>
    {fullwidth ? (
      <Wrapper>
        <Container>
          <Benefits
            title={title}
            text={text}
            benefits={benefits}
            highDensity={highDensity}
          />
        </Container>
      </Wrapper>
    ) : (
      <Benefits
        title={title}
        text={text}
        benefits={benefits}
        highDensity={highDensity}
      />
    )}
  </>
)

const Benefits = ({ title, text, benefits, highDensity }) => (
  <StyledBox>
    <Heading>{title}</Heading>
    {text && <RichContentTextBlock html={text} />}
    <Grid columns={[1, 1, highDensity ? 3 : 2]} mt={6}>
      {benefits.map(benefit => (
        <Box key={benefit.id}>
          <Benefit image={benefit.image} text={benefit.text} />
        </Box>
      ))}
    </Grid>
  </StyledBox>
)

const Wrapper = styled.div`
  background: #f3f6f8;
`

const StyledBox = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url(${customBorder});
  background-repeat: no-repeat;
  background-position: left 1rem;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default BenefitsBlock

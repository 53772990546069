import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { useCategory } from "../hooks/useCategory"
import { Link } from "gatsby"
import UrlScheme from "./urlScheme"

const NavCategories = ({ lang, slug }) => {
  const links = useCategory().filter(obj => {
    return obj.locale === lang && obj.application.slug === slug
  })
  return (
    <Links>
      {links.map(link => (
        <Link
          to={
            link.locale === "it"
              ? `/${UrlScheme["it"].applications}/${slug}/${link.slug}/`
              : `/${link.locale}/${
                  UrlScheme[link.locale].applications
                }/${slug}/${link.slug}/`
          }
          key={link.id}
          activeClassName="active"
        >
          {link.title}
        </Link>
      ))}
    </Links>
  )
}

const Links = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    font-size: 14px;
    color: black;
    text-decoration: none;
    &:hover {
      color: ${DesignTokens.colors.primary[500]};
    }
    &.active {
      color: ${DesignTokens.colors.primary[500]};
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: -1rem;
        width: 4px;
        height: 100%;
        background: ${DesignTokens.colors.primary[500]};
      }
    }
  }
`

export default NavCategories
